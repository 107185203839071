.projects-nav {
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 0.1rem solid var(--c1);
  border-top: 0.1rem solid var(--c1);
  padding: 1rem 0;
  margin-top: 2rem;
}

.projects-nav span {
  margin-left: 4rem;
}

.projects-nav label {
  margin-right: 1rem;
  font-size: 1.8rem;
}

@media (min-width: 600px) {
  .projects-nav {
    flex-direction: row;
    margin-top: 1rem;
  }
}
