.nav-btns {
  width: 100%;
  display: flex;
  justify-content: center;
  order: 2;
}

.page-btn {
  background-color: var(--bg);
  border: none;
  cursor: pointer;
  align-self: flex-end;
}

.prev {
  margin-right: 2rem;
}

.projects {
  order: 1;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 94%;
}

.projects-heading {
  color: white;
  padding-left: 0.8rem;
  padding-bottom: 0.4rem;
  width: 88%;
  margin-left: 1rem;
  font-weight: 400;
  font-size: 2.8rem;
}

.projects-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ph1 {
  border-bottom: 0.1rem solid var(--c5);
  border-left: 0.1rem solid var(--c6);
}

.ph2 {
  border-bottom: 0.1rem solid var(--c2);
  border-left: 0.1rem solid var(--c1);
}

@media (min-width: 600px) {
  .arrow {
    opacity: 1;
  }

  .nav-btns {
    position: absolute;
    justify-content: space-between;
    margin-top: 16rem;
  }

  .next {
    margin-left: auto;
    margin-right: -1rem;
  }

  .page-btn {
    align-self: center;
    background: rgba(0, 0, 0, 0);
  }

  .prev {
    margin-right: 0;
  }

  .projects {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    align-content: flex-start;
    padding: 0 4.8rem;
  }

  .projects-heading {
    margin-left: 4rem;
    width: 40rem;
  }
}
