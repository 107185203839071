@import "~react-image-gallery/styles/css/image-gallery.css";

.close-gallery {
  cursor: pointer;
  position: absolute;
  top: 0.6rem;
  right: -6rem;
  z-index: 1;
}

.gallery {
  position: fixed;
  top: 0;
  background-color: black;
  border: 0.2rem solid black;
}
