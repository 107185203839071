.home-nav {
  position: absolute;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4rem;
  top: 18rem;
}

.home-nav-link {
  color: white;
  font-size: 2.8rem;
  text-decoration: none;
  border: 0.1rem solid var(--c2);
  padding: 1rem;
  width: 20rem;
  margin-top: 2rem;
  text-align: center;
}

.home-nav-link:last-child {
  border-color: var(--c6);
}

.home-nav-link:hover {
  border-width: 0.2rem;
}

@media (min-width: 480px) {
  .home-nav {
    width: 98%;
    top: 22rem;
  }
}

@media (min-width: 961px) {
  .home-nav {
    flex-direction: row;
    top: 4rem;
    justify-content: space-between;
    width: 94%;
  }
}
