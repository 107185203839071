.dropdown {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.featured-projects .project-card {
  margin-bottom: 2rem;
}

.full-img {
  max-width: 100%;
  position: fixed;
  margin: auto;
  top: 10%;
}

.gallery {
  position: relative;
  width: 92vw;
  left: 3vw;
  z-index: 1;
}

.gallery-preview {
  position: relative;
}

.gallery-icon {
  position: absolute;
  background-color: #ddd;
  border-radius: 1.5rem;
  padding: 0.2rem;
  color: black;
  right: -0.4rem;
  bottom: 0.2rem;
}

.mini .project-card {
  border: 0.2rem solid var(--c5);
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100%;
  background-color: var(--bg);
  opacity: 0.7;
  top: 0;
}

.project-card {
  background-color: #222;
  border: 0.2rem solid var(--c3);
  border-radius: 1.5rem;
  color: white;
  width: 28rem;
  height: auto;
  padding: 0 2rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-card a {
  color: white;
  font-size: 2rem;
}

.project-card a:last-child {
  margin-left: 4rem;
}

.project-card h2 {
  text-align: center;
  font-weight: 400;
  font-size: 2.6rem;
  margin-top: 1rem;
}

.project-card-img {
  height: 14rem;
  width: auto !important;
  cursor: pointer;
  display: block;
}

.project-description {
  font-size: 2rem;
  margin-top: -0.8rem;
}

.project-link {
  display: flex;
  align-items: center;
}

.project-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.technology {
  cursor: default;
}

.technology-icon {
  width: 2.8rem;
  height: 2.8rem;
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -2rem;
  margin-bottom: 1rem;
  justify-content: center;
}

.technologies p,
svg {
  margin-right: 1rem;
}

@media (min-width: 600px) {
  .project-card {
    width: 34rem;
  }

  .project-card-img {
    width: 34rem;
  }
}
