.success-message {
  position: absolute;
  background-color: var(--c6);
  color: black;
  font-weight: 400;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 36rem;
  font-size: 1.9rem;
  z-index: 1;
  opacity: 0.99;
  margin-top: 4.8rem;
  animation-name: fadeOut;
  animation-duration: 5s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  to {
    opacity: 0;
    display: none;
  }
}
