.contact-btn {
  cursor: pointer;
  float: right;
  margin-bottom: 1rem;
  height: 4rem;
  width: 16rem;
  border: 0.2rem solid var(--c4);
  background-color: var(--bg);
  color: white;
  font-size: 1.8rem;
}

.contact-form {
  width: 96%;
  border: 0.1rem solid var(--c6);
  padding: 1rem;
}

.contact-input {
  width: 99%;
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.contact-links {
  width: 96%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 0.1rem solid var(--c5);
  margin-bottom: 1rem;
  margin-top: 2rem;
  padding: 1rem;
}

.contact-me {
  margin: auto;
  width: 36rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  height: 20rem;
}
