footer {
  background-color: #202020;
  min-height: 26rem;
  line-height: 5.2rem;
  width: 100%;
  position: static;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.footer-icons {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  margin-bottom: 0;
}

.copyright {
  color: white;
  float: left;
}

#mail-icon {
  font-size: 4rem;
  color: white;
}

.quote {
  color: white;
  text-align: center;
  font-size: 2rem;
  line-height: 2.4rem;
  width: 26rem;
}

@media (min-width: 1500px) {
  .copyright {
    margin-left: 1rem;
    order: 1;
  }

  footer {
    min-height: 8rem;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-icons {
    flex-basis: 10%;
    justify-content: space-around;
    align-items: flex-end;
    order: 3;
    margin-top: 1rem;
  }

  .quote {
    order: 2;
    width: auto;
    flex-basis: 1;
    font-size: 1.8rem;
  }
}
